<template>
  <main>
    <companyHead :subHeadNum="2"/>
    <section class="introMain">
      <h3 class="bottomLineTit">연혁</h3>
      <div class="subHistory">
        <ul class="subHistory__list clear">
          <li class="clear">
            <h4 data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">2020</h4>
            <i data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></i>
            <div class="subHistory__list--img" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <figure class="his05"></figure>
            </div>
            <div class="subHistory__list--txt" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <strong>케어네이션 서비스 런칭 및 투자 유치</strong>
              <span>Series-A</span>
              <p>HB인베스트먼트㈜ 투자 유치</p>
              <p>㈜LSK 인베스트먼트 투자 유치</p>
              <p>㈜하나벤처스 투자 유치</p>
              <p class="devide left">경영혁신형 중소기업 (Main-Biz) 인증</p>
              <p>기술혁신형 중소기업 (Inno-Biz) 인증</p>
              <p>근로시간 단축 확인</p>
              <p>미래성과공유기업 확인</p>
              <p>스마트 서비스 지원 사업 선정</p>
              <p>IP(지식재산)나래 프로그램 지원사업 선정</p>
              <p>케어네이션 Ver.1.0 런칭</p>
            </div>
          </li>
          <li class="clear">
            <h4 data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">2021</h4>
            <i data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></i>
            <div class="subHistory__list--img" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <figure class="his06"></figure>
            </div>
            <div class="subHistory__list--txt" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <strong>DATA LAB 구축 및 투자 유치</strong>
              <span>Pre- Series B</span>
              <p>HB인베스트먼트㈜ 투자 유치</p>
              <p>㈜LSK 인베스트먼트 투자 유치</p>
              <p>㈜하나벤처스 투자 유치</p>
              <p>삼성벤처투자㈜ 전략적 투자 유치 (삼성화재)</p>
              <p>신한금융투자㈜ 투자 유치</p>
              <p>새한창업투자㈜ 투자 유치</p>
              <p>삼성벤처투자㈜ 투자 유치</p>
              <p><b>누적 투자금액 235억원 달성</b></p>
              <p class="devide">삼성화재해상보험㈜ MOU 체결</p>
              <br />
              <p>보람상조 간병 서비스 활성화를 위한 MOU 체결</p>
              <br />
              <p>CARENATION DATA LAB 구축 <br /><small>(간병동향리포트 발간)</small></p>
              <br />
              <p>중소벤처기업부 스마트서비스 지원사업 우수 혁신기업 선정</p>
              <br />
              <p>고용노동부 강소기업 선정</p>
            </div>
          </li>
          <li class="clear">
            <h4 data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">2022</h4>
            <i data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></i>
            <div class="subHistory__list--img" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <figure class="his07"></figure>
            </div>
            <div class="subHistory__list--txt" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <strong>케어네이션 Ver 2.0 출시</strong>
              <span>Pre- Series B</span>
              <p>데일리 파트너스 투자유치</p>
              <p><b>누적 투자금액 255억원 달성</b></p>
              <p class="devide left">㈜씨엔컴퍼니 인수</p>
              <p>케어네이션 Ver.2.0 출시</p>
              <p>간병인 매칭 기업회원용 서비스 출시</p>
              <p>삼성화재 협업 모바일 간병인배상책임보험 출시</p>
              <p>삼성화재 동행서비스 특화 배상책임보험 도입</p>
              <p>기술역량 우수기업 선정 (T3 등급, 나이스평가 정보)</p>
            </div>
          </li>
          <li class="clear">
            <h4 data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">2023</h4>
            <i data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></i>
            <div class="subHistory__list--img" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <figure class="his08"></figure>
            </div>
            <div class="subHistory__list--txt" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <strong>동행 · 가사돌봄 서비스 출시</strong>
              <p>동행 서비스 출시</p>
              <p>가사돌봄 서비스 출시</p>
              <p>㈜그로우플러스 인수</p>
              <p>삼성화재 애니핏 간병서비스 연동 출시</p>
            </div>
            <i></i>
          </li>
          <li class="clear">
            <h4 data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">2024</h4>
            <i data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></i>
            <div class="subHistory__list--img" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <figure class="his09"></figure>
            </div>
            <div class="subHistory__list--txt" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <strong>신규 서비스 출시 및 투자 유치</strong>
              <span>Pre - Series B</span>
              <p>삼성벤처투자㈜ 후속 투자 유치</p>
              <p>㈜하나벤처스 후속 투자 유치</p>
              <p><b>누적 투자금액 305억원 달성</b></p>
              <p class="devide left">산후돌봄 서비스 출시</p>
              <p>장기요양 업무관리 프로그램 런칭</p>
              <p>벤처기업협회 우수벤처기업 선정</p>
            </div>
            <i></i>
          </li>
          <li class="clear">
            <h4 data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">2025</h4>
            <i data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></i>
            <div class="subHistory__list--img" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <figure class="his10"></figure>
            </div>
            <div class="subHistory__list--txt" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <strong>건강검진 B2B 서비스 출시</strong>
              <p>건강검진 B2B 서비스 출시</p>
            </div>
            <i></i>
          </li>
        </ul>
      </div>
    </section>
  </main>
</template>

<script>
import companyHead from './CompanyHead.vue';
export default {
  components: {
    companyHead
  }
}
</script>