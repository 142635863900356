<template>
  <article>
        <div class="introBan about">
          <h2>CARENATION</h2>
        </div>
        <!-- 모바일 화면에서 스크롤 시 해당 구조 sticky 클래스 추가 필요 -->
        <div class="introTabMenu">
          <ul>
            <li>
              <router-link to="/company/profile" :class="{'active' : subHeadNum == 1}">회사 소개</router-link>
            </li>
            <li>
              <router-link to="/company/history" :class="{'active' : subHeadNum == 2}">연혁</router-link>
            </li>
            <li>
              <router-link to="/company/ci" :class="{'active' : subHeadNum == 3}">CI</router-link>
            </li>
            <li>
              <router-link to="/company/people" :class="{'active' : subHeadNum == 4}">인재상</router-link>
            </li>
            <li>
              <router-link to="/company/benefits" :class="{'active' : subHeadNum == 5}">복리후생</router-link>
            </li>
          </ul>
        </div>
      </article>
</template>

<script>
export default {
  props : {
    subHeadNum : Number
  },
  mounted() {
    sessionStorage.setItem("subPick", this.subHeadNum);
  }
}
</script>
