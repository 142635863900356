<template>
  <main>
    <companyHead :subHeadNum="4"/>
    <section class="introMain careers">
      <div class="careersImgList">
        <div class="basicWrap">
          <h3 class="bottomLineTit left">
            평범한 사람들이 모여서<br />
            비범한 일을 해낸다.
          </h3>
          <p>
            케어네이션은 전문성, 창의성을 바탕으로 최고의 서비스를 제공하기 위해<br />
            끊임없이 노력하며 새로운 도전과 혁신을 멈추지 않습니다.<br />
            투명성, 존중과 배려를 바탕으로 회사를 운영하며 사회 발전에 기여하는 기업이 되고자
            노력합니다.
          </p>
        </div>
        <slick ref="slick" class="imgSlideWrap clear" :options="slickOptions2">
          <div class="imgSlideWrap__list imgSlide01">
            <img src="@/assets/img/img_talent01.jpg" alt="주식회사 케어네이션 회사 전경" />
          </div>
          <div class="imgSlideWrap__list imgSlide02">
            <img src="@/assets/img/img_talent02.jpg" alt="주식회사 케어네이션 회사 전경" />
          </div>
          <div class="imgSlideWrap__list imgSlide03">
            <img src="@/assets/img/img_talent03.jpg" alt="주식회사 케어네이션 회사 전경" />
          </div>
          <div class="imgSlideWrap__list imgSlide04">
            <img src="@/assets/img/img_talent04.jpg" alt="주식회사 케어네이션 회사 전경" />
          </div>
          <div class="imgSlideWrap__list imgSlide04">
            <img src="@/assets/img/img_talent05.jpg" alt="주식회사 케어네이션 회사 전경" />
          </div>
          <div class="imgSlideWrap__list imgSlide04">
            <img src="@/assets/img/img_talent06.jpg" alt="주식회사 케어네이션 회사 전경" />
          </div>
        </slick>
      </div>
      <div class="careersRecruit">
        <div class="basicWrap">
          <h3 class="bottomLineTit left">채용절차</h3>
          <article class="ciSliderWrap">
            <div class="cirImgList clear">
              <div class="cirImgList__item">
                <figure class="recruit01"></figure>
                <h4>서류 전형</h4>
              </div>
              <div class="cirImgList__item">
                <figure class="recruit02"></figure>
                <h4>1차 면접(실무)</h4>
              </div>
              <div class="cirImgList__item">
                <figure class="recruit03"></figure>
                <h4>최종결과</h4>
              </div>
            </div>
          </article>
          <div class="careers__sendMail">
            채용 관련 문의 :
            <a href="mailto:hr@carenation.kr">hr@carenation.kr</a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import companyHead from './CompanyHead.vue';
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
export default {
  data(){
    return{
      slickOptions2: {
      responsive: [
        {
          breakpoint: 10000,
          settings: "unslick"
        },
        {
          breakpoint: 1920,
          settings: {
            centerMode: true,
            centerPadding: "250px",
            slidesToShow: 1,
            infinite: true,
            arrows: false,
          }
        },
        {
          breakpoint: 1023,
          settings: {
            centerMode: true,
            centerPadding: '20px',
          }
        }
      ]
      }
    }
  },
  components: {
    companyHead,
    Slick
  }
}
</script>
