<template>
  <main>
    <companyHead :subHeadNum="3"/>
    <section class="introMain">
      <h3 class="bottomLineTit">CI</h3>
      <p>
        케어네이션은 CARE(돌봄)+NATION(국가)의 합성어로<br />
        환자와 돌봄 제공자를 이어주는 연결고리로 모든 돌봄 서비스를 하나의 플랫폼 안에서
        제공한다는 의미를 담고 있습니다.
      </p>
      <small>
        * 적용매체의 특수성으로 인해 색상표현이 불가능한 경우를 제외한<br />
        모든 상황에 동일하게 표현되어야 하며, 임의로 변경 사용할 수 없습니다.
      </small>
      <div class="ci">
        <div class="ci__logo"></div>
        <div class="ci__downBtn clear">
          <a href="/CARENATION_BI_PNG.zip">PNG 다운로드</a>
          <a href="/CARENATION_BI_AI.zip">AI 다운로드</a>
        </div>
        <div class="ci__concept">
          <div class="basicWrap clear">
            <div class="ci__concept--txt">
              <h3 class="bottomLineTit left">Concept</h3>
              <p>
                케어네이션의 CI는 ‘환자와 돌봄 제공자를 이어주는 연결고리’를 표현합니다.<br />
                하나로 연결된 고리 형태와 따뜻한 브라운 계열의 브랜드 컬러는<br />
                케어네이션의 무한한 가능성과 신뢰받는 서비스를 상징합니다.
              </p>
            </div>
            <div class="ci__concept--color">
              <div>
                <p>C 38</p>
                <p>M 67</p>
                <p>Y 71</p>
                <p>K 30</p>
              </div>
              <div>
                <p>C 33</p>
                <p>M 54</p>
                <p>Y 56</p>
                <p>K 09</p>
              </div>
            </div>
            <article class="ciSliderWrap">
              <swiper class="cirImgList clear" :options="swiperOption">
                <swiper-slide class="cirImgList__item">
                  <figure class="ci01">
                    <span></span>
                  </figure>
                  <h4>신뢰</h4>
                </swiper-slide>
                <swiper-slide class="cirImgList__item">
                  <figure class="ci02">
                    <span></span>
                  </figure>
                  <h4>전문성</h4>
                </swiper-slide>
                <swiper-slide class="cirImgList__item">
                  <figure class="ci03">
                    <span></span>
                  </figure>
                  <h4>소통</h4>
                </swiper-slide>
              </swiper>
              <div class="ciSliderWrap__pager clear" slot="pagination"></div>
            </article>
            <!-- <article class="ciSliderWrap">
              <div class="cirImgList ci clear">
                <div class="cirImgList__item">
                  <figure class="ci01">
                    <span></span>
                  </figure>
                  <h4>신뢰</h4>
                </div>
                <div class="cirImgList__item">
                  <figure class="ci02">
                    <span></span>
                  </figure>
                  <h4>전문성</h4>
                </div>
                <div class="cirImgList__item">
                  <figure class="ci03">
                    <span></span>
                  </figure>
                  <h4>소통</h4>
                </div>
              </div>
              <div class="ciSliderWrap__pager clear">
                <button type="button" class="active">1</button>
                <button type="button">2</button>
                <button type="button">3</button>
              </div>
            </article> -->
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import companyHead from './CompanyHead.vue';
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from 'vue-awesome-swiper';
export default {
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        enabled: false,
        followFinger: false,
        breakpoints: {
          1024: {
            enabled : true,
            followFinger: true,
            slidesPerView: '1',
            loop: true,
            pagination : {
              el: ".ciSliderWrap__pager",
              clickable : true,
              bulletClass: 'default',
              bulletActiveClass: 'active',
              renderBullet: function (index, className) {
                return '<button type="button" class="' + className + '">' + '</button>';
              }
            },
          }
        }
      }
    }
  },
  components: {
    swiper,
    swiperSlide,
    companyHead
  }
}
</script>