export default {
  invest : [
    { 
      num : 1,
      img: require('@/assets/img/img_invest01.png'),
      name:"HB INVESTMENT"
    },
    { 
      num : 2,
      img: require('@/assets/img/img_invest02.png'),
      name:"LSK INVESTMENT"
    },
    { 
      num : 3,
      img: require('@/assets/img/img_invest03.png'),
      name:"하나벤처스"
    },
    { 
      num : 4,
      img: require('@/assets/img/img_invest04.png'),
      name:"삼성화재"
    },
    { 
      num : 5,
      img: require('@/assets/img/img_invest05.png'),
      name:"삼성벤처투자"
    },
    { 
      num : 6,
      img: require('@/assets/img/img_invest06.png'),
      name:"신한투자증권"
    },
    { 
      num : 7,
      img: require('@/assets/img/img_invest07.png'),
      name:"KODIT 신용보증기금"
    },
    { 
      num : 8,
      img: require('@/assets/img/img_invest08.png'),
      name:"새한창업투자"
    },
    { 
      num : 9,
      img: require('@/assets/img/img_invest09.png'),
      name:"DAYLI Partners"
    }
  ],
}