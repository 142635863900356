<template>
  <header>
    <div class="headerWrap clear">
      <h1 @click="mobOff()">
        <router-link to="/">
          <img src="@/assets/img/img_logo01.png" alt="케어네이션" />
        </router-link>
      </h1>
      <nav>
        <!-- 
          기본 : mobileBtn
          mobileBtn 클릭 : mobileBtn open
          -->
        <button
          type="button"
          class="mobileBtn"
          @click="mobOpen = !mobOpen"
          :class="mobOpen ? 'open' : ''"
        >
          전체 메뉴
        </button>
        <ul class="headerWrap__menu clear">
          <li @click="mobOff()">
            <router-link to="/service/protector" :class="isActive == 1 ? 'active' : ''"
              >간병인 찾기</router-link
            >
          </li>
          <li @click="mobOff()">
            <router-link to="/service/caregiver" :class="isActive == 2 ? 'active' : ''"
              >간병일감 찾기</router-link
            >
          </li>
          <li @click="mobOff()">
            <router-link to="/service/hospital" :class="isActive == 3 ? 'active' : ''"
              >병원회원</router-link
            >
          </li>
          <li @click="mobOff()">
            <router-link to="/customer" :class="isActive == 5 ? 'active' : ''"
              >고객센터</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      mobOpen: false,
      isActive: 0,
    };
  },
  methods: {
    mobOff() {
      if (this.mobOpen == true) {
        this.mobOpen = false;
      }
    },
    isActiveOn(index) {
      this.isActive = index;
    },
  },
  watch: {
    $route: function () {
      if (this.$route.path == "/" || this.$route.path == "/main") {
        this.isActiveOn(0);
      } else if (this.$route.path == "/service/protector") {
        this.isActiveOn(1);
      } else if (this.$route.path == "/service/caregiver") {
        this.isActiveOn(2);
      } else if (this.$route.path == "/service/hospital") {
        this.isActiveOn(3);
      } else if (this.$route.path == "/service/company") {
        this.isActiveOn(4);
      } else if (this.$route.path == "/customer") {
        this.isActiveOn(5);
      }
    },
  },
};
</script>
<style>
.headerWrap__menu a.active:after {
  width: 100%;
}
</style>
