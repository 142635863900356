<template>
  <footer>
    <div class="footerWrap">
      <h2 class="a11y-hidden">회사 관련 정보</h2>
      <div class="footerWrap__provLink">
        <div class="basicWrap">
          <ul class="footerWrap__provLink--list clear">
            <li>
              <router-link :to="{ name: 'companyProfile' }">회사소개</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'protector' }">보호자 이용약관</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'protectorPrivacy' }">보호자 개인정보 처리방침</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'caregiver' }">간병인 이용약관</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'caregiverPrivacy' }">간병인 개인정보 처리방침</router-link>
            </li>
            <li><router-link :to="{ name: 'location' }">위치기반 서비스 이용약관</router-link></li>
          </ul>
        </div>
      </div>
      <div class="footerWrap__corpInfo clear">
        <div class="footerWrap__corpInfo--aco" :class="toggle ? 'on' : ''">
          <img src="@/assets/img/img_logo02.png" alt="케어네이션" />
          <h2>
            <button type="button" @click="toggle = !toggle">케어네이션 회사 정보 더보기</button>
          </h2>
          <div>
            <p>서비스명 : 케어네이션</p>
            <p>주소지 : 서울 강남구 테헤란로8길 37, 한동빌딩 5층</p>
            <p>회사명 : 케어네이션 주식회사</p>
            <p>사업자등록번호 : 212-86-05451</p>
            <p>대표자명 : 김견원, 서대건 │ 개인정보관리책임자 : 서대건</p>
            <p>통신판매업신고 : 제 2024-서울강남-04074 호</p>
            <p>제휴문의 : 070-4681-0443</p>
            <p>제휴 이메일 : carenation@carenation.kr</p>
            <p>고객센터 이메일 : help@carenation.kr</p>
            <p>호스팅 사업자 : Naver Cloud Platform</p>
            <p>유료직업소개서 등록번호 : 제 2024-3220250-14-5-00030 호</p>
            <small>
              케어네이션 주식회사는 통신판매중개자로서 거래에 필요한 시스템을 운영 및
              제공합니다.<br />
              보호자와 케어메이트 사이에 발생 분쟁에 대한 책임은 보호자와 케어메이트에게 있습니다.
            </small>
          </div>
        </div>
        <div class="footerWrap__corpInfo--right">
          <ul class="snsLink clear">
            <li>
              <a href="https://blog.naver.com/carenation" class="blog" target="_blank">네이버 블로그</a>
            </li>
            <li>
              <a href="https://www.youtube.com/@carenation_" class="youtube" target="_blank">유튜브</a>
            </li>
            <li>
              <a href="https://www.facebook.com/CARENATION" class="facebook" target="_blank">페이스북</a>
            </li>
            <li>
              <a href="https://pf.kakao.com/_QaGxjxb" class="kakao" target="_blank">카카오톡 채널</a>
            </li>
            <li>
              <a href="https://www.instagram.com/carenation_official" class="insta" target="_blank">인스타그램</a>
            </li>
          </ul>
          <div class="csWrap">
            <div class="csWrap__csCenter">
              <p>고객센터<b>1811-5949</b></p>
              <span><b>월~토요일</b> : 오전 10시 ~ 오후 5시 30분</span>
              <span><b>금요일</b> : 오전 10시 ~ 오후 5시 <small>(채팅상담 동일)</small></span>
              <span><b>휴무일</b> : 일요일, 명절, 근로자의 날</span>
              <span>카카오톡 상담시간 : 고객센터와 동일합니다</span>
            </div>
            <div class="csWrap__familySite">
              <button type="button" @click="footSwitch">Family site</button>
              <!-- 
                  기본값 : csWrap__familySite--list
                  버튼 선택시 : csWrap__familySite--list open
                -->
              <ul class="csWrap__familySite--list" :class="footFlag ? 'open' : ''">
                <li><a href="https://datalab.carenation.co.kr/" target="_blank">DATA LAB.</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="topBtn" @click="scrollTop()" v-if="curScroll == false">맨위로</button>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      toggle: false,
      curScroll: true,
      footFlag: false,
    };
  },
  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    checkScrollPosition() {
      this.curScroll = window.pageYOffset === 0;
    },
    footSwitch() {
      this.footFlag = !this.footFlag;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.checkScrollPosition);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkScrollPosition);
  },
};
</script>

<style></style>
