<template>
  <main>
      <companyHead :subHeadNum="5"/>
      <section class="introMain">
        <h3 class="bottomLineTit">복리후생</h3>
        <ul class="careers__infoList">
          <li class="clear" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
            <div class="careers__infoList--img"></div>
            <div class="careers__infoList--txt">
              <h4>점심 식대 및 간식비 지원</h4>
              <p>
                든든하게 먹고 업무에 집중할 수 있도록 점심 식대와 <br />
                간식비, 야근 시 저녁 식대를 지원합니다.
              </p>
            </div>
          </li>
          <li class="clear" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
            <div class="careers__infoList--img"></div>
            <div class="careers__infoList--txt">
              <h4>복지 포인트 및 휴가 지급</h4>
              <p>
                더 풍성하고 행복한 일상을 위해 복지 포인트와<br />
                여름휴가, 생일 반차를 제공합니다.
              </p>
            </div>
          </li>
          <li class="clear" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
            <div class="careers__infoList--img"></div>
            <div class="careers__infoList--txt">
              <h4>매주 금요일 5시 조기 퇴근</h4>
              <p>
                케어네이션은 일과 삶의 균형을 중요하게 생각합니다.<br />
                조금 더 여유로운 저녁을 보낼 수 있도록 1시간 조기 퇴근 제도를 운영합니다.
              </p>
            </div>
          </li>
          <li class="clear" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
            <div class="careers__infoList--img"></div>
            <div class="careers__infoList--txt">
              <h4>도서 및 의무 교육 지원</h4>
              <p>
                구성원들의 성장과 역량 향상을 위해 도서와 교육비 지원을 <br />
                아끼지 않습니다.
              </p>
            </div>
          </li>
          <li class="clear" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
            <div class="careers__infoList--img"></div>
            <div class="careers__infoList--txt">
              <h4>쾌적한 휴식공간</h4>
              <p>
                업무 능률 향상을 위해 쾌적하고 편안한 휴식공간을 제공합니다.<br />
                카페테리아에는 공기청정기, 최신식 게임기, 보드게임과 안마의자가<br />
                준비되어 있습니다.
              </p>
            </div>
          </li>
        </ul>
        <div class="careers__certify">
          <div class="basicWrap">
            <h3 class="bottomLineTit left">인증현황</h3>
            <ul class="careers__certify--list clear">
              <li>
                <h4>
                  기업부설연구소<br />
                  인증
                </h4>
                <p>기술력 향상 기업 인증</p>
              </li>
              <li>
                <h4>
                  근로(노동)시간<br />
                  단축 확인
                </h4>
                <p>주 52시간 이하 근무 인증</p>
              </li>
              <li>
                <h4>
                  메인비즈<br />
                  인증
                </h4>
                <p>경영혁신 중소기업 인증</p>
              </li>
              <li>
                <h4>
                  이노비즈<br />
                  인증
                </h4>
                <p>기술혁신 중소기업 인증</p>
              </li>
              <li>
                <h4>
                  미래성과공유기업<br />
                  확인
                </h4>
                <p>임직원 성과 공유 인증</p>
              </li>
              <li>
                <h4>
                  벤처기업<br />
                  인증
                </h4>
                <p>벤처 투자 기업 인증</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
</template>

<script>
import companyHead from './CompanyHead.vue';
export default {
  components: {
    companyHead
  }
}
</script>