<template>
  <main>
    <companyHead :subHeadNum="1"/>
    <section class="introMain">
      <h3 class="bottomLineTit">회사소개</h3>
      <p>
        케어네이션은 돌봄과 관련된 모든 서비스를 제공하는 “통합 돌봄 플랫폼”으로 나아가고
        있습니다.<br />
        적정 간병비 산출 알고리즘, 케어메이트 가상 앱 통장, AI 기반 최적 간병인 매칭 등을 업계
        최초로 도입하였으며 빅데이터를 활용하여 참여자 간의 정보 격차를 해소하고 돌봄 서비스의
        접근성을 확대하는 등 서비스 혁신을 이끌어갑니다.
      </p>
      <div class="introMain__img hmc clear">
        <div data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></div>
        <div data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></div>
      </div>
      <div class="introMain__ideol">
        <h3 class="bottomLineTit left">회사이념</h3>
        <div class="introMain__slide">
          <button type="button" class="introMain__slide--btn prev">left</button>
          <button type="button" class="introMain__slide--btn next">right</button>
          <swiper class="introMainSlideWrap clear" :options="swiperOption">
            <swiper-slide class="introMainSlideWrap__list respect">
              <h4>존중</h4>
              <p>
                아픈 환자들과 돌봄 제공자, 의료기관의 입장을<br />
                최우선으로 생각한다.
              </p>
            </swiper-slide>
            <swiper-slide class="introMainSlideWrap__list value">
              <h4>가치</h4>
              <p>
                합리적인 서비스와 올바른 돌봄 문화를 위해<br />
                끊임없이 고민하여 기대 이상의 서비스를 제공한다.
              </p>
            </swiper-slide>
            <swiper-slide class="introMainSlideWrap__list innov">
              <h4>혁신</h4>
              <p>
                변화를 두려워하지 않고<br />
                0.1의 차이가 아닌 1의 차이를 만들어 낸다.
              </p>
            </swiper-slide>
            <swiper-slide class="introMainSlideWrap__list creative">
              <h4>창의 / 자율</h4>
              <p>
                자유로운 사고를 바탕으로 새로운 도전을<br />
                시도하고 책임과 권한에 따라 주인의식을 가지고 일한다.
              </p>
            </swiper-slide>
            <swiper-slide class="introMainSlideWrap__list commu">
              <h4>소통</h4>
              <p>
                직급에 상관없는 상호 존칭을 통해<br />
                수평적으로 소통한다. 책임은 부서장이 공은 팀원이 인정받는다.
              </p>
            </swiper-slide>
            <swiper-slide class="introMainSlideWrap__list develop">
              <h4>자기계발</h4>
              <p>
                각 분야에서 최고의 결과를 내기 위해<br />
                최선을 다하며 회사는 역량 향상을 위한<br />
                지원을 아끼지 않는다.
              </p>
            </swiper-slide>
          </swiper>
          <article class="dotPager clear"></article>
        </div>
      </div>
      <article class="introMain__ban invest">
        <div class="basicWrap clear">
          <div class="introMain__ban--left">
            <h3 class="bottomLineTit left">주요 투자사</h3>
            <p>
              케어네이션은 누적 투자 금액 305억 원을 기록하며 사업 가치를 인정받았습니다.<br />
              간병 업계 1위를 넘어 도전과 혁신으로 돌봄 시장의 역사를 새롭게 쓰고 있습니다.
            </p>
          </div>
          <ul class="introMain__ban--investImg clear">
            <li v-for="investItem in investList" :key="investItem.num">
              <img :src="investItem.img" :alt="investItem.name">
            </li>
          </ul>
        </div>
      </article>
    </section>
  </main>
</template>

<script>
import companyHead from './CompanyHead.vue';
import clientList from '@/assets/js/clientList';
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from 'vue-awesome-swiper';
export default {
  data() {
    return {
      investList : clientList.invest,
      swiperOption: {
        slidesPerView: "3",
        slidesPerGroup: 3,
        loop: true,
        spaceBetween: 0,
        autoplay: {
          delay: 2000
        },
        navigation: {
          nextEl: ".introMain__slide--btn.next",
          prevEl: ".introMain__slide--btn.prev",
        },
        pagination: {
          el: ".dotPager",
          clickable: true,
          bulletClass: "default",
          bulletActiveClass: "active",
          renderBullet: function (index, className) {
            return '<button type="button" class="' + className + '">' + "</button>";
          },
        },
        breakpoints: {
          600: {
            slidesPerView: "1",
            slidesPerGroup: 1,
          }
        }
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
    companyHead
  },
};
</script>

<style scope>
.swiper-notification {
  display: none;
}
</style>